import clsx from "clsx";
import Card from "components/Card";
import style from "components/Carousel.module.scss";
import { ArrowLeft, ArrowRight } from "components/Icons";
import { motion } from "framer-motion";
import { defaultRootVariants, easeOutQuint } from "lib/constants";
import { observer } from "mobx-react";
import React, { useRef, useState } from "react";
import { useInView } from "react-intersection-observer";
import { A11y, Keyboard, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

const contentVariants = {
  initial: {
    opacity: 0,
    transition: { duration: 1, ease: easeOutQuint },
  },
  animate: {
    opacity: 1,
    transition: { duration: 1, ease: easeOutQuint },
  },
  exit: {
    opacity: 0,
    transition: { duration: 1, ease: easeOutQuint },
  },
};

const Carousel = observer(({ cards = [] }) => {
  const [status, setStatus] = useState("isBeginning");

  const swiperInstance = useRef(null);

  const handleSlideChange = () => {
    if (swiperInstance.current.isBeginning) setStatus("isBeginning");
    if (swiperInstance.current.isEnd) setStatus("isEnd");
    if (!swiperInstance.current.isBeginning && !swiperInstance.current.isEnd)
      setStatus("");
  };

  const { ref, inView, entry } = useInView({
    triggerOnce: true,
  });

  const className = clsx(style.root);

  const PaginationButton = ({ direction }) => {
    const handlePagination = () => {
      if (direction === 1) {
        swiperInstance.current.slideNext();
      } else {
        swiperInstance.current.slidePrev();
      }
    };

    const icon =
      direction === 1 ? (
        <ArrowRight size={23} strokeWidth={1} />
      ) : (
        <ArrowLeft size={23} strokeWidth={1} />
      );

    const buttonClassName = clsx(style.navButton, {
      [style.navButton___isDisabled]:
        (direction === -1 && status === "isBeginning") ||
        (direction === 1 && status === "isEnd"),
    });

    return (
      <button
        onClick={handlePagination}
        direction={direction}
        type="button"
        className={buttonClassName}
      >
        {icon}
      </button>
    );
  };

  return (
    <motion.div
      className={className}
      ref={ref}
      variants={defaultRootVariants}
      initial="initial"
      animate={inView ? "animate" : "initial"}
      exit="exit"
    >
      <motion.div className={style.carousel} variants={contentVariants}>
        <Swiper
          modules={[Navigation, A11y, Keyboard]}
          a11y
          slidesPerView={1.175}
          grabCursor
          spaceBetween={20}
          keyboard
          className={style.slides}
          slidesOffsetBefore={20}
          slidesOffsetAfter={20}
          threshold={10}
          preventClicks
          onSlideChange={handleSlideChange}
          breakpoints={{
            // when window width is >= 1280
            1536: {
              slidesOffsetBefore: 80,
              slidesOffsetAfter: 80,
              spaceBetween: 40,
              slidesPerView: 4.5,
            },
            // when window width is >= 1280
            1280: {
              slidesOffsetBefore: 80,
              slidesOffsetAfter: 80,
              spaceBetween: 40,
              slidesPerView: 3.5,
            },
            // when window width is >= 1024
            1024: {
              slidesOffsetBefore: 80,
              slidesOffsetAfter: 80,
              spaceBetween: 40,
              slidesPerView: 2.5,
            },
            // when window width is >= 768
            768: {
              slidesOffsetBefore: 40,
              slidesOffsetAfter: 40,
              spaceBetween: 20,
              slidesPerView: 2.5,
            },
            // when window width is >= 576
            576: {
              slidesOffsetBefore: 20,
              slidesOffsetAfter: 20,
              spaceBetween: 20,
              slidesPerView: 1.75,
            },
          }}
          onSwiper={(swiper) => {
            swiperInstance.current = swiper;
          }}
        >
          {cards.map((card) => {
            return (
              <SwiperSlide key={card.id} className={style.slide}>
                <Card
                  size={card.size}
                  heading={card.heading}
                  image={card.image?.[0]}
                  caption={card.caption}
                  textOverlay={card.textOverlay}
                  target={card.target}
                  footer={card.footer}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </motion.div>
      <div className={style.footer}>
        <div className={style.nav}>
          <PaginationButton direction={-1} />
          <PaginationButton direction={1} />
        </div>
      </div>
    </motion.div>
  );
});

export default Carousel;
