import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";

import Carousel from "@/components/Carousel";

dayjs.extend(duration);

const EventsCarousel = ({ events }) => {
  const cards = events.map((event) => {
    const startDateTime = dayjs(event.startDateTime);
    const endDateTime = dayjs(event.endDateTime);
    const duration = endDateTime.diff(startDateTime, "minute");

    return {
      id: event.id,
      heading: event.title,
      image: event.image,
      caption: event.summary,
      target: { type: "entry", element: event },
      size: "sm",
      footer: `${duration} minutes`,
    };
  });

  return <Carousel cards={cards} />;
};

export default EventsCarousel;
