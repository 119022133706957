import Link from "next/link";
import React from "react";

const Target = ({
  className,
  target,
  children,
  onClick = () => null,
  scroll = true,
  style = {},
}) => {
  const returnElement = () => {
    return (
      <div className={className} style={style}>
        {children}
      </div>
    );
  };

  if (!target || target.type === "") {
    return returnElement();
  }

  if (target.type === "href" && !!target.href) {
    if (!target.href) return returnElement();

    return (
      <Link
        href={target.href}
        scroll={scroll}
        prefetch={false}
        className={className}
        draggable={false}
        onClick={onClick}
        style={style}
      >
        {children}
      </Link>
    );
  }

  if (target.type === "entry" && !!target.element) {
    const href = `/${target.element.uri}`;

    if (!href) return returnElement();

    return (
      <Link
        href={href}
        scroll={scroll}
        prefetch={false}
        className={className}
        draggable={false}
        onClick={onClick}
        style={style}
      >
        {children}
      </Link>
    );
  }

  if (target.type === "category" && !!target.element) {
    const href = `/${target.element.uri}`;

    if (!href) return returnElement();

    return (
      <Link
        href={href}
        scroll={scroll}
        prefetch={false}
        className={className}
        draggable={false}
        onClick={onClick}
        style={style}
      >
        {children}
      </Link>
    );
  }

  if (target.type === "asset" && !!target.element) {
    return (
      <a
        href={target.url}
        className={className}
        draggable={false}
        download
        target="_blank"
        rel="noreferrer"
        onClick={onClick}
        style={style}
      >
        {children}
      </a>
    );
  }

  if (target.type === "url") {
    return (
      <a
        href={target.url}
        className={className}
        target="_blank"
        rel="noreferrer"
        draggable={false}
        onClick={onClick}
      >
        {children}
      </a>
    );
  }

  if (target.type === "email") {
    return (
      <a href={target.url} className={className}>
        {children}
      </a>
    );
  }

  return returnElement();
};

export default Target;
