import clsx from "clsx";
import { observer } from "mobx-react";
import React, { ReactNode } from "react";

const Page = ({
  children,
  theme = "dark",
  withHero = false,
}: {
  children: ReactNode;
  theme?: "dark" | "light";
  withHero?: boolean;
}) => {
  const className = clsx("Page", "min-h-screen", {
    "bg-gray-50": theme === "dark",
    "bg-white": theme === "light",
    "": withHero,
    "pt-16 lg:pt-28": !withHero,
  });

  return <div className={className}>{children}</div>;
};

export default Page;
