import clsx from "clsx";
import style from "components/Block.module.scss";
import React from "react";

const Block = ({
  children = [],
  spaceAbove = "none",
  spaceBelow = "none",
  typeHandle = null,
  backgroundColor = "transparent",
}) => {
  // Determine the block class
  const className = clsx(
    style.root,
    style[`root___spaceAbove___${spaceAbove}`],
    style[`root___spaceBelow___${spaceBelow}`],
    { [style[`root___${typeHandle}`]]: typeHandle },
  );

  return (
    <div className={className} style={{ backgroundColor }}>
      {children}
    </div>
  );
};

export default Block;
