import clsx from "clsx";
import React, { cloneElement } from "react";
import { useInView } from "react-intersection-observer";
import Balancer from "react-wrap-balancer";
import Standfirst from "./Standfirst";

const Heading = ({
  heading = null,
  headingType = "h2",
  standfirst = null,
  layout = "horizontal",
  alignment = "left",
}): JSX.Element => {
  const { ref, inView } = useInView({
    triggerOnce: true,
  });

  const HeadingWrapper = ({ children }) => {
    if (headingType === "h1")
      return (
        <h1
          className={clsx(
            "Heading__heading",
            "text-3xl lg:text-4xl xl:text-5xl",
          )}
        >
          <Balancer>{children}</Balancer>
        </h1>
      );
    if (headingType === "h2")
      return (
        <h2
          className={clsx(
            "Heading__heading",
            "text-3xl lg:text-4xl xl:text-5xl",
          )}
        >
          <Balancer>{children}</Balancer>
        </h2>
      );
    if (headingType === "h3")
      return (
        <h3
          className={clsx(
            "Heading__heading",
            "text-3xl lg:text-4xl xl:text-5xl",
          )}
        >
          <Balancer>{children}</Balancer>
        </h3>
      );
    return (
      <h2
        className={clsx("Heading__heading", "text-3xl lg:text-4xl xl:text-5xl")}
      >
        <Balancer>{children}</Balancer>
      </h2>
    );
  };

  return (
    <div className={clsx("Heading", "")} ref={ref}>
      <div
        className={clsx("Heading__content", "px-6 lg:px-16 flex", {
          "flex-col space-y-1 md:space-y-2 ": layout === "vertical",
          "flex-col lg:flex-row space-y-5 lg:space-y-0 container":
            layout === "horizontal",
          "justify-center text-center":
            alignment === "center" && layout === "horizontal",
          "items-center text-center":
            alignment === "center" && layout === "vertical",
        })}
      >
        <div
          className={clsx("Heading__header", "", {
            "lg:pr-16": layout === "horizontal",
            "": layout === "vertical",
            "lg:w-2/5": layout === "horizontal",
          })}
        >
          <HeadingWrapper>{heading}</HeadingWrapper>
        </div>

        {!!standfirst && (
          <div
            className={clsx("Heading__main", {
              "lg:w-3/5": layout === "horizontal",
              " ": alignment !== "center",
            })}
          >
            <div
              className={clsx(
                "Heading__standfirst",
                "text-2xl text-gray-700 lg:font-light xl:text-3xl",
                {
                  "mx-auto max-w-2xl":
                    layout === "vertical" && alignment === "center",
                },
                {
                  "max-w-3xl": layout === "vertical" && alignment === "left",
                },
              )}
            >
              <Standfirst standfirst={standfirst} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Heading;
