import JsonLdScript from "@/components/JsonLdScript";
import { Seomatic as ReactSeomatic } from "react-seomatic";

const Seomatic = (props) => {
  const { Head, ...rest } = props;

  const { metaJsonLdContainer, ...seomaticProps } = rest;

  return (
    <>
      <ReactSeomatic Head={Head} {...seomaticProps} />
      <JsonLdScript jsonLdDataString={metaJsonLdContainer} />
    </>
  );
};

export default Seomatic;
